<template>
  <div id="ModalContatosCadastro">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="text-white title-page body-1">Cadastro de Contato</span>
          <v-btn
          icon
          dark
          color="#F2F6F7"
          @click="store_Pessoa.dialogContatosCadastro = false"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
              class="form-container"
              ref="form"
              v-model="valid">

                <v-text-field
                v-model="dados.pessoa_contato_nome"
                class="mb-0"
                light
                background-color="#FFF"
                label="Nome"
                placeholder="Nome"
                filled
                outlined
                required
                dense
                :rules="nameRules"
                ></v-text-field>

                <v-text-field
                v-model="dados.pessoa_contato_fone1"
                class="mt-n2"
                light
                background-color="#FFF"
                label="Telefone 1"
                placeholder="(xx)xxxx-xxxx"
                filled
                outlined
                required
                dense
                v-mask="MASK_TELEFONE"
                :rules="foneObrigatorioRules"
                ></v-text-field>

                <v-text-field
                v-model="dados.pessoa_contato_fone2"
                class="mt-n2"
                light
                background-color="#FFF"
                label="Telefone 2"
                placeholder="(xx)xxxx-xxxx"
                filled
                outlined
                dense
                v-mask="MASK_TELEFONE"
                :rules="foneRules"
                ></v-text-field>

                <v-text-field
                v-model="dados.pessoa_contato_fone3"
                class="mt-n2"
                light
                background-color="#FFF"
                label="Telefone 3"
                placeholder="(xx)xxxx-xxxx"
                filled
                outlined
                required
                dense
                v-mask="MASK_TELEFONE"
                :rules="foneRules"
                ></v-text-field>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Pessoa.dialogContatosCadastro = false"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
          :disabled="!valid"
          @click="validate()"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import store_site from "../../store/store_site"
import store_Pessoa from "./store_Pessoa";
import { API } from "../../services/API";
import { telefoneIsValid } from "../../services/funcoes";
import { COR_PRINCIPAL, MASK_TELEFONE } from "../../services/constantes";

export default {
  name: "ModalContatosCadastro",

  props: [
    'dados_modal_contato'],

  data() {
    return {
      
      store_site        : store_site,
      store_Pessoa      : store_Pessoa,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      MASK_TELEFONE     :MASK_TELEFONE,
      
      valid             : true,
      form              : false,

      nameRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      foneObrigatorioRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && telefoneIsValid(value))) || "O telefone não é válido",
      ],
      foneRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && telefoneIsValid(value))) || "O telefone não é válido",
      ],
    };
  },

  computed:{
    dados() {
      //console.log('dados', this.dados_modal_contato)
      return this.dados_modal_contato
    }
  },

  methods: {
    
    async validate () {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {

        // Gravando ----------------
        if (this.dados) {
          let ls_Rel = null;
          if (this.store_Pessoa.acao == 'C') {
            if (!!this.dados.cod_pessoa_contato) {
              this.dados.acao = 'E'
            }
            ls_Rel = await API.post(`pessoa_contato`, JSON.stringify(this.dados));
            if (ls_Rel.status == 200) {
              this.store_Pessoa.pessoa_edicao.pessoa_contatos = ls_Rel.data.result.data
                
              this.store_site.alert_cor       = "#00A000";
              this.store_site.alert_timeout   = 1500;
              this.store_site.alert_msg       = ls_Rel.data.result.result.trim();
              this.store_site.alert           = true;
            } else {
              this.store_site.alert_cor       = "#FF0000";
              this.store_site.alert_timeout   = 10000;
              this.store_site.alert_msg       = ls_Rel.data.errors.trim();
              this.store_site.alert           = true;
            }

          }
          // else if (this.store_Pessoa.acao == 'I') {
          //   console.log('4')
          //   if (this.store_Pessoa.pessoa_edicao.pessoa_enderecos && this.store_Pessoa.pessoa_edicao.pessoa_enderecos.cod_pessoa_endereco) {
          //     console.log('5')
          //     this.$emit('update:dados_modal_endereco', this.dados);
          //   }              
          //   else {
          //     console.log('6')
          //     if (!this.store_Pessoa.pessoa_edicao.pessoa_enderecos)
          //       this.store_Pessoa.pessoa_edicao.pessoa_enderecos = []
          //     this.store_Pessoa.pessoa_edicao.pessoa_enderecos.push(this.dados)
          //     this.store_Pessoa.pessoa_edicao.pessoa_enderecos[0].pessoa_endereco_municipio = {nome: null, uf: null}
          //     console.log(this.store_Pessoa.pessoa_edicao)
          //   }
          // }

          this.store_Pessoa.dialogContatosCadastro = false;

          }

        }
      }
    },

};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 540px;
  overflow-y: auto;
}

.btn {
  width: 100px;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 
</style>